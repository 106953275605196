import { KataruInvokeFnOnWeb } from '@axieinfinity/kataru'

export const checkForUpdate: KataruInvokeFnOnWeb<'app:check_for_update'> = () =>
	window.bridge?.kataru.invoke('app:check_for_update')

export const quitAndUpdate: KataruInvokeFnOnWeb<'app:quit_and_update'> = () =>
	window.bridge?.kataru.invoke('app:quit_and_update')

export const getAppVersion: KataruInvokeFnOnWeb<'app:version'> = () => window.bridge?.kataru.invoke('app:version')
export const quitApp: KataruInvokeFnOnWeb<'app:quit'> = () => window.bridge?.kataru.invoke('app:quit')
export const restartApp: KataruInvokeFnOnWeb<'app:restart'> = () => window.bridge?.kataru.invoke('app:restart')

export const handleWindow: KataruInvokeFnOnWeb<'app:window_handler'> = (handle) =>
	window.bridge?.kataru.invoke('app:window_handler', handle)

export const setCookie: KataruInvokeFnOnWeb<'app:set_cookie'> = (cookieDetails) =>
	window.bridge?.kataru.invoke('app:set_cookie', cookieDetails)

export const getCookie: KataruInvokeFnOnWeb<'app:get_cookie'> = (name) =>
	window.bridge?.kataru.invoke('app:get_cookie', name)

export const removeCookie: KataruInvokeFnOnWeb<'app:remove_cookie'> = (name) =>
	window.bridge?.kataru.invoke('app:remove_cookie', name)

export const getDevConfig: KataruInvokeFnOnWeb<'app:get_dev_config'> = () =>
	window.bridge?.kataru.invoke('app:get_dev_config')
