import { Button, Dialog, Intent } from '@axieinfinity/konan'
import { useMemoizedFn } from 'ahooks'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { match } from 'ts-pattern'

import { handleOverlayCreateWallet } from '#/core/handlers/overlay'
import { createWalletDialogAtom } from '#/core/stores'
import { useCaptureEvent } from '#/hooks'
import { refetchProfile } from '#/hooks/global/auth'

import styles from './create-wallet.module.scss'

export const CreateWalletDialog: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const [{ isVisible }, setOpenDialog] = useAtom(createWalletDialogAtom)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const closeDialog = () => setOpenDialog({ isVisible: false })

  const handleCloseDialog = useMemoizedFn(() => {
    setError('')
    closeDialog()
  })

  const onCreateWallet = async () => {
    captureEvent('Click create Ronin Waypoint wallet')
    setIsLoading(true)
    const idAuthData = await handleOverlayCreateWallet()
    match(idAuthData)
      .with({ type: 'success' }, () => {
        refetchProfile()
        closeDialog()
      })
      .with({ type: 'fail' }, (data) => {
        if (data.error.code !== 4001) {
          setError('Something went wrong!')
        }
      })
      .otherwise(() => null)

    setIsLoading(false)
  }

  return (
    <Dialog size="medium" visible={isVisible} onClose={handleCloseDialog}>
      <section className={styles.section}>
        <figure className={styles.image}>
          <img src="/assets/shining-baby-bird.png" />
        </figure>
        <h2 className={styles.title}>Create your Keyless Wallet</h2>
        {error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <p className={styles.description}>
            To play this game, you need to have the Keyless Wallet first.
          </p>
        )}
        <div className={styles.actions}>
          <Button
            intent={Intent.Default}
            text="Cancel"
            onClick={handleCloseDialog}
          />
          <Button
            intent={Intent.Primary}
            text="Create wallet"
            loading={isLoading}
            onClick={onCreateWallet}
          />
        </div>
      </section>
    </Dialog>
  )
}
