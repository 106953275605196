import { HubUpdateInfo } from '@axieinfinity/kataru'
import { Button, ButtonVariant, Dialog, Intent, Typography } from '@axieinfinity/konan'
import { ProgressInfo } from 'electron-updater'
import { useEffect, useState } from 'react'
import { match, P } from 'ts-pattern'

import { Markdown } from '#/components/common/markdown'
import handlers from '#/core/handlers'
import { logger } from '#/core/logger'

import { UpdaterDownloading } from '../indicators/updater-downloading'
import styles from './updater.module.scss'

export const Updater: React.FC = () => {
	const [updateAvailable, setUpdateAvailable] = useState(true)
	const [progress, setProgress] = useState<ProgressInfo | undefined>(undefined)
	const [info, setInfo] = useState<HubUpdateInfo | undefined>(undefined)
	const [error, setError] = useState<string | undefined>(undefined)
	const [retry, setRetry] = useState(false)

	useEffect(() => {
		const unsubscribe = window.bridge?.kataru.on('app:updater_info', (_, data) => {
			match(data)
				.with({ type: 'checking-for-update' }, () => null)
				.with({ type: 'error' }, ({ message }) => {
					setProgress(undefined)
					setError(message)
				})
				.with({ type: 'download-progress' }, ({ payload }) => setProgress(payload))
				.with({ type: 'update-available' }, ({ payload }) => logger.info('New update available', payload as unknown as Record<string, unknown>))
				.with({ type: 'update-not-available' }, () => null)
				.with({ type: 'update-downloaded' }, ({ payload }) => {
					setProgress(undefined)
					setInfo(payload)
					setUpdateAvailable(true)
				})
				.exhaustive()
		})

		return () => void unsubscribe?.()
	}, [])

	const onLater = () => setUpdateAvailable(false)
	const onUnderstand = () => handlers.quitApp()
	const onRestart = () => {
		if (retry) {
			handlers.restartApp()
		} else {
			handlers.quitAndUpdate()
			setRetry(true)
		}
	}

	if (progress && progress.percent !== 100) return <UpdaterDownloading isVisible percentage={progress.percent} />
	if (!info) return null

	return (
		<Dialog visible={updateAvailable} size="medium" maskClosable={false} className={styles.dialog}>
			<Typography.Text level="3" bold className={styles.title}>
				Update Mavis Hub
			</Typography.Text>

			<img src="/logo-blue.svg" className={styles.logo} />

			<div className={styles.paraghraph}>
				<Typography.Text>
					A new version of Mavis Hub {info?.version && '(' + info.version + ') '}is{' '}
					{error ? 'available' : 'ready to be installed'}.
				</Typography.Text>
				<Typography.Text>Restart the application is required to finish the update.</Typography.Text>
			</div>

			{info?.releaseNotes && (
				<div className={styles.note}>
					<div className={styles.scroll}>
						{match(info.releaseNotes)
							.with(P.string, (value) => <Markdown>{value}</Markdown>)
							.with(P._, (releases) =>
								releases.map(
									(release) =>
										release.note && (
											<div className={styles.release} key={crypto.randomUUID()}>
												<Typography.Text bold level="1">
													{release.version}
												</Typography.Text>
												<Markdown>{release.note}</Markdown>
											</div>
										)
								)
							)
							.exhaustive()}
					</div>
				</div>
			)}

			{error && (
				<div className={styles.error}>
					An error occurred while updating Mavis Hub.
					<br />
					Please report this issue to our support team for assistance, or alternatively, you can update manually by
					downloading the latest version from our{' '}
					<a href="https://welcome.skymavis.com/download" target="_blank" rel="noopener noreferrer">
						official download page
					</a>
					. Thank you!
					<br />
					<pre className={styles.errorDetails}>{error}</pre>
				</div>
			)}

			{match(error)
				.with(P.string, () => (
					<div className={styles.closeOnError}>
						<div className={styles.actions}>
							{info?.force !== true && <Button text="Later" variant={ButtonVariant.Plain} onClick={onLater} />}
							<Button text="I Understand" className={styles.understand} onClick={onUnderstand} />
						</div>
						<span>
							By clicking <strong>Understand</strong>, you acknowledge that the application will be closed, and you will
							be required to perform a manual update.
						</span>
					</div>
				))
				.with(undefined, () => (
					<div className={styles.actions}>
						<Button text="Restart & Install" intent={Intent.Primary} className={styles.restart} onClick={onRestart} />
					</div>
				))
				.exhaustive()}
		</Dialog>
	)
}
